import React, { useState, useCallback } from "react";
import styled from "styled-components";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  CircularProgress,
} from "@mui/material";
import { CloudUploadIcon, DownloadIcon } from "../constant/Icons";
import { CancelButtonAlert, ConfirmButton } from "../../styled/Button";

const UploadArea = styled.div`
  border: 2px dashed #ccc;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  cursor: pointer;
  margin-bottom: 20px;
  transition: background-color 0.3s;

  &:hover,
  &.drag-active {
    background-color: #f0f0f0;
  }
`;

const HiddenInput = styled.input`
  display: none;
`;

const FilePreview = styled.div`
  margin-top: 20px;
  padding: 10px;
  background-color: #f0f0f0;
  border-radius: 4px;
`;

const ImportDialog = ({ open, onClose, onImport, downloadTemplate }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [isUploading, setIsUploading] = useState(false);
  const [isDragging, setIsDragging] = useState(false);

  const handleFileChange = (event) => {
    const file = event.target.files?.[0] || event.dataTransfer?.files?.[0];
    if (file) {
      if (!file.type.includes('application/vnd.')) {
        alert('Please upload a valid Excel file (XLSX).');
        return;
      }
      setSelectedFile(file);
    }
  };

  const handleDragEnter = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(true);
  }, []);

  const handleDragLeave = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
  }, []);

  const handleDragOver = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
  }, []);

  const handleDrop = useCallback((e) => {
    e.preventDefault();
    e.stopPropagation();
    setIsDragging(false);
    handleFileChange(e);
  }, []);

  const handleConfirmUpload = async () => {
    if (selectedFile) {
      setIsUploading(true);
      try {
        await onImport(selectedFile);
        handleCancel();
      } catch (error) {
        console.error("Error uploading file:", error);
      } finally {
        setIsUploading(false);
      }
    }
  };

  const handleCancel = () => {
    setSelectedFile(null);
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleCancel}>
      <DialogTitle>Import Users</DialogTitle>
      <DialogContent>
        <Typography variant="body1" gutterBottom>
          To import users, please upload a CSV file with the following columns:
          email, password, name, position, phone, etc.
        </Typography>
        <UploadArea
          className={isDragging ? "drag-active" : ""}
          onDragEnter={handleDragEnter}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
        >
          <CloudUploadIcon sx={{ fontSize: 50 }} />
          <Typography variant="h6">Drag and drop file here</Typography>
          <Typography variant="body1">or</Typography>
          <Button variant="contained" component="label">
            Select File
            <HiddenInput
              type="file"
              onChange={handleFileChange}
              accept=".csv,.xlsx,.xls"
            />
          </Button>
        </UploadArea>
        {selectedFile && (
          <FilePreview>
            <Typography variant="body2">
              Selected file: {selectedFile.name}
            </Typography>
          </FilePreview>
        )}
        <Button
          variant="outlined"
          onClick={downloadTemplate}
          style={{ marginTop: "20px" }}
        >
          <DownloadIcon />
          Download Template
        </Button>
      </DialogContent>
      <DialogActions>
        <CancelButtonAlert onClick={handleCancel}>Cancel</CancelButtonAlert>
        <ConfirmButton
          onClick={handleConfirmUpload}
          color="primary"
          disabled={!selectedFile || isUploading}
          startIcon={isUploading ? <CircularProgress size={20} /> : null}
        >
          {isUploading ? "Uploading..." : "Confirm Upload"}
        </ConfirmButton>
      </DialogActions>
    </Dialog>
  );
};

export default ImportDialog;
