// pages/Login.js
import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
  Box,
  TextField,
  Button,
  Typography,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { Visibility, VisibilityOff } from "../components/constant/Icons";
import { GlobalImageURLs } from "../components/constant/Global";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import CompanyThunks from "../redux/thunks/companyThunks";
import { LoginButton } from "../styled/Button";
import { StyledTabs, StyledTab } from "../styled/BaseStyles";
import { useLocation } from "react-router-dom";
import NotiDialog from "../components/dialogs/NotiDialog";
import {
  NotiType,
  NotiAction,
  NotiName,
  getNotiInformation,
} from "../components/constant/Notification";
import SuperAdminThunks from "../redux/thunks/superAdminThunks";
import CompanyActions from "../redux/actions/companyActions";
import useStatusChangeNotifications from "../hooks/useStatusChangeNotifications";

const LoginContainer = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 100vh;
  @media (max-width: 600px) {
    margin: 20px 0;
  }
`;

const FormContainer = styled(Box)`
  width: 100%;
  max-width: 400px;
  padding: 24px;
  border-radius: 8px;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  background-color: #ffffff;
  @media (max-width: 600px) {
    width: 100%;
    max-width: 300px;
    border-radius: 0;
  }
`;
const LogoWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
`;

const Logo = styled.img`
  width: 200px;
`;

const Register = () => {
  const dispatch = useDispatch();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [rePassword, setRePassword] = useState("");
  const [showRePassword, setShowRePassword] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const navigate = useNavigate();
  const [name, setName] = useState("");
  const [invitationCode, setInvitationCode] = useState("");
  const [openNotiDialog, setOpenNotiDialog] = useState(false);
  const [notiInfo, setNotiInfo] = useState({});
  const isEmailExists = useSelector((state) => state.company.isEmailExists);
  const isValidInvitationLink = useSelector(
    (state) => state.superAdmin.isValidToken
  );

  const useQuery = () => {
    return new URLSearchParams(useLocation().search);
  };
  const query = useQuery();

  const { resetRegisterStatus } = CompanyActions;
  const statusConfigs = [
    {
      stateSlice: "company",
      statusKey: "registerStatus",
      action: NotiAction.Register,
      name: NotiName.Company,
      resetAction: resetRegisterStatus,
    },
  ];

  const handleCloseNotiDialog = useStatusChangeNotifications(
    statusConfigs,
    setNotiInfo,
    setOpenNotiDialog
  );

  const handleCloseDialog = () => {
    handleCloseNotiDialog();
    navigate("/login");
  };

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleClickShowRePassword = () => {
    setShowRePassword(!showRePassword);
  };

  const handleTabChange = (newValue) => {
    setTabValue(newValue);
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (password !== rePassword) {
      alert("Password and Re-Password are not matched");
      return;
    }
    // check valid invitation link first
    // if (!isValidInvitationLink) {
    //   setOpenNotiDialog(true);
    //   setNotiInfo(
    //     getNotiInformation(
    //       NotiAction.Register,
    //       NotiName.Company,
    //       NotiType.InvalidInvitationLink
    //     )
    //   );
    //   return;
    // }
    if (isEmailExists) {
      alert("Email already exists");
      return;
    }
    await dispatch(
      CompanyThunks.register(invitationCode, { name, email, password })
    );
  };

  useEffect(() => {
    const code = query.get("invitationCode");
    if (code) {
      setInvitationCode(code);
    } else {
      //navigate("/login");
    }
  }, [query, navigate]);

  useEffect(() => {
    if (invitationCode) {
      const fetch = async () => {
        await dispatch(SuperAdminThunks.isValidInvitationLink(invitationCode));
      };
      fetch();
    }
  }, [invitationCode, dispatch]);

  useEffect(() => {
    if (email) {
      dispatch(CompanyThunks.isEmailExists(email));
    }
  }, [email, dispatch]);

  return (
    <LoginContainer>
      <LogoWrapper>
        <Logo src={GlobalImageURLs.YoovLogoB} alt="YOOV Logo" />
        <Typography variant="h6">Name Card Admin Panel</Typography>
      </LogoWrapper>
      <FormContainer mt={2}>
        <Typography variant="h5">Register</Typography>
        <StyledTabs value={tabValue} onChange={handleTabChange} centered>
          <StyledTab label="Register Account" />
        </StyledTabs>
        <form onSubmit={handleSubmit}>
          <TextField
            label="Company Name"
            variant="outlined"
            margin="normal"
            fullWidth
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
            autoComplete="on"
          />
          <TextField
            label="Email Address"
            variant="outlined"
            margin="normal"
            fullWidth
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <FormControl variant="outlined" margin="normal" fullWidth>
            <InputLabel htmlFor="password">Password</InputLabel>
            <OutlinedInput
              id="password"
              type={showPassword ? "text" : "password"}
              value={password}
              required
              autoComplete="on"
              onChange={(e) => setPassword(e.target.value)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowPassword}
                    edge="end"
                  >
                    {showPassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Password"
            />
          </FormControl>
          <FormControl variant="outlined" margin="normal" fullWidth>
            <InputLabel htmlFor="re-password">Re-Password</InputLabel>
            <OutlinedInput
              id="re-password"
              type={showRePassword ? "text" : "password"}
              value={rePassword}
              required
              autoComplete="on"
              onChange={(e) => setRePassword(e.target.value)}
              endAdornment={
                <InputAdornment position="end">
                  <IconButton
                    aria-label="toggle password visibility"
                    onClick={handleClickShowRePassword}
                    edge="end"
                  >
                    {showRePassword ? <VisibilityOff /> : <Visibility />}
                  </IconButton>
                </InputAdornment>
              }
              label="Re-Password"
            />
          </FormControl>

          <LoginButton type="submit">Register</LoginButton>
        </form>

        <Typography variant="body2" mt={2}>
          Already have an account?{" "}
          <Button
            onClick={() => {
              navigate("/login");
            }}
          >
            {" "}
            Back to Login{" "}
          </Button>
        </Typography>
      </FormContainer>
      {openNotiDialog && (
        <NotiDialog
          openDialog={openNotiDialog}
          onClose={handleCloseDialog}
          notiInfo={notiInfo}
        />
      )}
    </LoginContainer>
  );
};

export default Register;
