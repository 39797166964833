// utils/getErrorMessage.js
export const getErrorMessage = (error) => {
  if (error.response && error.response.data && error.response.data.meta) {
    return error.response.data.meta.error_message;
  } else if (error.message) {
    return error.message;
  } else {
    return "An unknown error occurred";
  }
};
