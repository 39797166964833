import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
// import AuthThunks from "../redux/thunks/authThunks";

const Callback = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    const processCallback = async () => {
      const query = new URLSearchParams(window.location.search);
      const code = query.get("code");

      if (code) {
        try {
          // await dispatch(AuthThunks.handleOauthCallback({ code }));
          navigate("/"); // Replace with your desired route
        } catch (error) {
          console.error("Error during OAuth callback", error);
        }
      } else {
        console.error("No code found in query parameters");
      }
    };

    processCallback();
  }, [dispatch, navigate]);

  return (
    <div>
      <p>Processing login...</p>
    </div>
  );
};

export default Callback;