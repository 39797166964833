import axiosApi from "../../../utils/axiosApi";
import NameCardUserActions from "../../actions/nameCard/userActions";
import { getErrorMessage } from "../../../utils/getErrorMessage";
import { errorMessages } from "../../../error/errorMessages";

const apiURL = "name-card/users";

export const getTargets = (targetUserId) => async (dispatch) => {
  try {
    const response = await axiosApi.get(`${apiURL}/${targetUserId}`);
    console.log("response", response);
    dispatch(NameCardUserActions.getTargetsSuccess(response.data));
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    dispatch(
      NameCardUserActions.getTargetsFailure(
        errorMessage || errorMessages.nameCardUser.notFound
      )
    );
  }
};

export const getAll = () => async (dispatch) => {
  try {
    const response = await axiosApi.get(apiURL);
    dispatch(NameCardUserActions.getAllSuccess(response.data));
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    dispatch(
      NameCardUserActions.getAllFailure(
        errorMessage || errorMessages.nameCardUser.fetchAllFailed
      )
    );
  }
};

export const createTarget = (data) => async (dispatch) => {
  try {
    const { imageType, profileImg, profileImgPreSignedUrl, ...newData } = data;
    const response = await axiosApi.post(apiURL, newData);
    const { id } = response.data;

    if (imageType) {
      await dispatch(uploadTargetImg(profileImg, imageType, id));
    }
    dispatch(NameCardUserActions.createTargetSuccess(response.data));
    await dispatch(getAll());
    return response.data;
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    dispatch(
      NameCardUserActions.createTargetFailure(
        errorMessage || errorMessages.nameCardUser.createFailed
      )
    );
  }
};

export const updateTarget = (data) => async (dispatch) => {
  try {
    const { imageType, profileImg, profileImgPreSignedUrl, id, ...newData } =
      data;
    if (imageType) {
      await dispatch(uploadTargetImg(profileImg, imageType, id));
    }
    const response = await axiosApi.put(`${apiURL}/${id}`, newData);
    dispatch(NameCardUserActions.updateTargetSuccess(response.data));
    dispatch(getAll());
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    dispatch(
      NameCardUserActions.updateTargetFailure(
        errorMessage || errorMessages.nameCardUser.updateFailed
      )
    );
  }
};

export const deleteTarget = (id) => async (dispatch) => {
  try {
    const response = await axiosApi.delete(`${apiURL}/${id}`);
    dispatch(NameCardUserActions.deleteTargetSuccess(response.data));
    dispatch(getAll());
  } catch (error) {
    const errorMessage = getErrorMessage(error);
    dispatch(
      NameCardUserActions.deleteTargetFailure(
        errorMessage || errorMessages.nameCardUser.deleteFailed
      )
    );
  }
};

export const uploadTargetImg =
  (file, fileType, nameCardUserId) => async (dispatch) => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("fileType", fileType);
    try {
      const response = await axiosApi.post(
        `${apiURL}/upload-files/${nameCardUserId}`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      let url = ''
      if(response.data.uploadedFiles) {
        url = response.data.uploadedFiles[0][fileType]
      }
      else {
        url = response.data.uploadedFileUrls[0][fileType]
      }
      return {
        fileType,
        url,
      };
    } catch (error) {
      throw new Error('Invalid image')
      const errorMessage = getErrorMessage(error);
      dispatch(
        NameCardUserActions.uploadTargetFileFailure(
          errorMessage || errorMessages.nameCardUser.uploadFailed
        )
      );
    }
  };

const NameCardUserThunks = {
  getTargets,
  getAll,
  createTarget,
  updateTarget,
  deleteTarget,
  uploadTargetImg,
};

export default NameCardUserThunks;
