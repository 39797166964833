import { useCallback } from "react";

const useImageUploadWithFormState = (setFormValues) => {
  const handleImageChange = useCallback(
    (
        imageType,
        urlFieldName = `${imageType}PreSignedUrl`,
        fileFieldName = imageType
      ) =>
      (e) => {
        const file = e.target.files[0];
        if (file) {
          const allowedTypes = ['image/jpeg', 'image/png', 'image/gif'];
          if (!allowedTypes.includes(file.type)) {
            alert('Please upload a valid image file (JPEG, PNG, GIF).');
            return;
          }
          const reader = new FileReader();
          reader.onloadend = () => {
            setFormValues((prevValues) => ({
              ...prevValues,
              [urlFieldName]: reader.result,
              [fileFieldName]: file,
              imageType: imageType,
            }));
          };
          reader.readAsDataURL(file);
        }
      },
    [setFormValues]
  );

  return handleImageChange;
};

export default useImageUploadWithFormState;
